import React from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';

function SEOHead({ title = '', description = '', image, children }) {
  const router = useRouter();

  return (
    <Head>
      <title>{title}</title>
      <meta property="og:title" content={title} />
      <meta property="twitter:title" content={title} />
      <meta name="apple-mobile-web-app-title" content={title} />
      <meta name="description" content={description} />
      <meta property="og:description" content={description} />
      <meta property="twitter:description" content={description} />
      <meta property="og:site_name"
            content="Вишлист онлайн, список подарков, покупок, фильмов или товаров" />
      {image &&
        <>
          <meta property="og:image" content={image} />
          <meta property="vk:image" content={image} />
          <meta property="fb:image" content={image} />
          <meta property="twitter:image" content={image} />
          <meta name="twitter:image:alt" content={title} />
        </>
      }
      <meta property="og:url" content={`https://isforme.ru${router.route}`} />
      <meta property="twitter:url" content={`https://isforme.ru${router.route}`} />
      {children}
    </Head>
  );
}

export default SEOHead;
